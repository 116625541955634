.footer {
    background-color: #272727;
    font-family: 'Barlow', sans-serif, Arial, Helvetica;
    margin-top: 1rem;
    padding: 1rem;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;   
    text-align: center; 
    color: #ACACAC;
    font-size: 16px;
}

