body {
    background-color: #000000;
    font-family: 'Barlow Condensed', sans-serif, Arial, Helvetica;
}

h2 {
    color: #ACACAC;
    font-family: 'Barlow Condensed', sans-serif, Arial, Helvetica;
    font-size: 45px;
    font-weight: 800;
}

.blended-a {
    color: #ACACAC;
    text-decoration: none;

}

.paragraph-home {
    font-family: 'Barlow', sans-serif, Arial, Helvetica;
    color: #ACACAC;
    font-size: 18px;
}

.paragraph-body {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 25px;
    padding-right: 25px;
    font-family: 'Barlow', sans-serif, Arial, Helvetica;
    color: #ACACAC;
    font-size: 18px;
}

.center {
    text-align: center;
}